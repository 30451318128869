<!--
 * @Author: Neko
 * @Date: 2021-01-16 10:43:32
 * @LastEditTime: 2021-03-22 10:47:27
 * @LastEditors: Neko
-->
<template>
  <el-dialog
    class="check-form__dialog"
    :visible="visible"
    title="实名身份证认证"
    width="698px"
    @close="onCloseCheckDialog"
  >
    <div class="tips">
      购买跨境电商产品需要进行实名认证
    </div>

    <el-form
      ref="checkForm"
      class="address-form editor-form check-form"
      label-width="120px"
      label-position="left"
      :model="formData"
      :rules="ruler"
    >
      <el-form-item label="手机号码：">
        {{ getHiddenMobile(userData.mobile) }}
      </el-form-item>

      <el-form-item label="姓名：" prop="username">
        <el-input v-model="formData.username" :disabled="isView" />
      </el-form-item>

      <el-form-item label="身份证号码：" prop="idCardNo">
        <el-input v-model="formData.idCardNo" :disabled="isView" />
      </el-form-item>
    </el-form>

    <p class="description">
      请填写正确的个人身份证信息并授权我们向外汇管理局和海关申报，
      我们承诺妥善保管及使用您的信息。
      身份证信息需与手机号码的注册身份证证一致，
      否则无法用过验证，每日验证次数上限2次，请注意填写！
    </p>

    <div v-if="!isView" class="check-buttons">
      <el-checkbox v-model="isCheck" class="checkbox">
        同意<a class="link" href="javascript:void(0)">《代理购结汇协议》</a>
      </el-checkbox>

      <div class="buttons">
        <el-button :disabled="!isCheck" class="button active" @click="onClickSubmitBtn">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { computed, reactive, ref, toRef, toRefs, watch } from '@vue/composition-api'

import { useRequest } from '@/utils/request'

export default {
  name: 'Auth',

  emits: ['close', 'success'],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit, root }) {
    const store = root.$store
    const checkForm = ref(null)

    const data = reactive({
      userData: computed(() => store.state.user.userData || {}),
      userInfo: computed(() => store.state.user.userInfo || {}),

      formData: {
        idCardNo: '',
        username: ''
      },

      ruler: {
        username: { required: true, message: '请输入', trigger: 'blur' },
        idCardNo: { required: true, message: '请输入', trigger: 'blur' }
      },

      onceToken: '',

      isCheck: false,
      isView: false // 查看模式
    })

    const onCloseCheckDialog = _ => {
      if (!data.isView) {
        data.formData = {
          idCardNo: '',
          username: ''
        }
        checkForm.value.resetFields()
      }

      emit('close')
    }

    const getHiddenMobile = mobile => {
      if (mobile && mobile.length <= 0) return
      const arr = Array.from(mobile)
      return arr.slice(0, 4).join('') + '***' + arr.slice(-4).join('')
    }

    const { fetch: getTokenFetch } = useRequest('auth/getOnceToken', {
      initialData: toRef(data, 'onceToken'),
      immediate: false
    })

    watch(() => props.visible, res => {
      if (res) {
        getTokenFetch()
      }
    })

    watch(() => data.userInfo, res => {
      if (res) {
        const { hiddenIdCard, hiddenName } = res
        if ((hiddenIdCard || '').length > 0) {
          data.isView = true
          data.formData = {
            idCardNo: hiddenIdCard,
            username: hiddenName
          }
        }
      }
    })

    const { fetch, data: bindAuthResult } = useRequest('auth/handleBindAuthAction', {
      data: computed(() => ({
        ...data.formData,
        onceToken: data.onceToken
      })),
      immediate: false
    })

    const onClickSubmitBtn = _ => {
      if (!checkForm.value) return
      checkForm.value.validate(isValid => {
        if (isValid) {
          fetch()
        }
      })
    }

    watch(() => bindAuthResult.value, res => {
      emit('success')
    })

    return {
      ...toRefs(data),
      checkForm,

      onCloseCheckDialog,
      getHiddenMobile,
      onClickSubmitBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.address-form {
  .buttons {
    text-align: center;
    margin-top: 25px;

    .button {
      width: 206px;
      padding: 0;
      border-color: inherit;
      line-height: 36px;
      font-weight: normal;
      background: #4981E8;
      color: #fff;
    }
  }

  ::v-deep .el-form-item__label {
    line-height: 24px;
    color: #666;
  }

  ::v-deep .el-select {
    width: 100%;
  }

  ::v-deep .el-input__inner, ::v-deep .el-textarea__inner {
    border-radius: 0;
  }

  &.edit-form {
    ::v-deep .el-form-item__label {
      line-height: 40px;
      color: #666;
    }

    .buttons {
      text-align: left;

      .button {
        width: 99px;

        &.white {
          background: #fff;
          color: #4981E8;
        }
      }
    }
  }
}

.check-form__dialog {
  ::v-deep .el-dialog__body {
    padding: 0 0 132px;
  }

  .tips {
    padding: 0 15px;
    font-size: 14px;
    line-height: 32px;
    color: #FF5E00;
    background: #FFF5DF;
  }

  .check-form {
    padding: 24px 149px 24px 32px;

    ::v-deep .el-form-item__label {
      line-height: 40px;
    }
  }

  .description {
    display: flex;
    padding: 0 32px;
    line-height: 20px;
    font-size: 12px;
    color: #444;

    &::before {
      content: '※';
      display: inline-block;
      margin-right: 8px;

      color: #FF0000;
    }
  }

  .check-buttons {
    margin-top: 63px;
    text-align: center;

    .checkbox {

      ::v-deep .el-checkbox__label {
        font-size: 12px;
        color: #000;
      }

      ::v-deep .el-checkbox__inner {
        border-radius: 50%;
      }
    }

    .link {
      font-size: 12px;
      color: #B1752D;
    }

    .buttons {
      margin-top: 8px;

      .button {
        width: 200px;
        border: none;
        background: #CCCCCC;
        color: #fff;

        &.active {
          background: #4981E8;
        }
      }
    }
  }
}
</style>
