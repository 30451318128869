<!--
 * @Author: Neko
 * @Date: 2021-01-08 09:50:33
 * @LastEditTime: 2021-10-16 14:31:05
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="submit__container">

    <div class="submit__wrap">
      <div class="submit__title-wrap">
        <h2 class="submit__title">
          <span class="title">{{ $t('order.settlement') }}</span>
        </h2>
      </div>

      <div class="submit__body">
        <div class="submit__body-wrap">
          <div class="left">
            <div class="base__container">
              <section class="base__section address">
                <div class="header">
                  <h3 class="title">{{ $t('order.Consigneeinformation') }}</h3>

                  <a class="link" href="javascript:void(0)" @click="onClickChangeAddressBtn">
                    <!-- <i class="icon el-icon-circle-plus" /> -->
                    {{ $t('order.Changeaddress') }}
                  </a>
                </div>

                <div class="content">
                  <AddressForm :disabled="!!addressList.length" :data="addressList[defaultAddressIndex]" :default="1" @confirm="onConfirmAddressForm" />
                </div>

                <el-dialog append-to-body :title="$t('order.Selectaddress')" :visible="isShowDialog" @close="onCloseSelectAddressDialog">
                  <el-row>
                    <el-col v-for="(item, index) in addressList" :key="item.id" :span="12">
                      <AddressItem
                        class="item"
                        :data="item"
                        :is-default="defaultAddressIndex === index ? 1 : 0"

                        @set-default="onSetDefault(index)"
                        @delete="onDeleteAddress(index)"
                        @edit="onEditAddressItem"
                      />
                    </el-col>
                  </el-row>
                </el-dialog>

                <!-- <div class="content">
                  <div class="address__list" :style="!showMoreAddress ? 'height: auto;' : ''">
                    <AddressItem
                      v-for="(item, index) in addressList"
                      :key="item.id"
                      class="item"
                      :data="item"
                      :is-default="defaultAddressIndex === index ? 1 : 0"

                      @set-default="onSetDefault(index)"
                      @delete="onDeleteAddress(index)"
                      @edit="onEditAddressItem"
                    />
                  </div>

                  <div v-if="showMoreAddress" class="more-btn">
                    <a href="javascript:void(0)" class="btn" @click="onMoreAddress">
                      更多地址
                      <i class="icon el-icon-d-arrow-right" />
                    </a>
                  </div>
                </div> -->
              </section>

              <address-dialog :id="addressId" :visible.sync="visible" :is-edit="isEdit" @success="onSuccess" />

              <section class="base__section">
                <div class="header">
                  <h3 class="title">{{ $t('order.Paymentmethod') }}</h3>
                </div>

                <div class="content">
                  <ul class="pay__list">
                    <li
                      v-for="item in tableData.payList"
                      :key="item.id"
                      class="item"
                      :class="{ active: payType.id === item.id }"
                      @click="onClickPayItem(item)"
                    >
                      <a class="link" href="javascript:void(0)">
                        {{ item.name.toUpperCase() }}
                        <!-- <i class="icon el-icon-check" /> -->
                      </a>
                    </li>
                  </ul>
                </div>
              </section>

              <!-- <section class="base__section">
                <div class="header">
                  <h3 class="title">商品清单</h3>
                </div>

                <div class="content">
                  <GoodsTable :data="tableData" />
                </div>
              </section> -->

              <section class="goods__section">
                <div class="shop-header">
                  <i class="icon el-icon-s-shop" />
                  <span class="name">{{ tableData.storeName }}</span>
                </div>

                <div class="goods-content">
                  <GoodsItem
                    v-for="item in (tableData.cartGoodsList || [])"
                    :key="item.id"
                    :data="item"
                    :show-operation="false"
                    :currency="item.currencyUnit"
                  />
                </div>
              </section>
            </div>
          </div>

          <div class="right">
            <div class="right-wrap">
              <h3 class="price__header">{{ $t('order.priceinformation') }}</h3>

              <div class="price__detail">
                <dl class="item">
                  <dt>{{ $t('order.Totalamountgoods') }}</dt>
                  <dd>{{ tableData.currencyUnit }}{{ $convertNumberFloat(tableData.goodsTotal || 0) }}</dd>
                </dl>

                <dl class="item">
                  <dt>{{ $t('order.freight') }}</dt>
                  <dd>{{ tableData.currencyUnit }}{{ $convertNumberFloat(tableData.freight || 0) }}</dd>
                </dl>
              </div>

              <div class="price__detail no-border-bottom">
                <dl class="item">
                  <dt>{{ $t('order.Totalorderamount') }}</dt>
                  <dd class="red">{{ tableData.currencyUnit }}{{ $convertNumberFloat(tableData.paymentPrice || 0) }}</dd>
                </dl>
              </div>

              <div class="price__policy">
                <el-checkbox v-model="isAgreed" class="checkbox-wrap">
                  <div class="checkbox">
                    {{ $t('order.readPolicy') }}
                    <router-link
                      class="link"
                      :to="{
                        name: 'ArticlePage',
                        query: { name: 'TERMS_OF_CONDITIONS' }
                      }"
                      target="_blank"
                    >
                      {{ $t('order.termsOfUse') }}
                    </router-link>、
                    <router-link
                      class="link"
                      :to="{
                        name: 'ArticlePage',
                        query: { name: 'PRIVACY_POLICY' }
                      }"
                      target="_blank"
                    >
                      {{ $t('order.private_policy') }}
                    </router-link>、
                    and
                    <router-link
                      class="link"
                      :to="{
                        name: 'ArticlePage',
                        query: { name: 'RETURN_REFUND' }
                      }"
                      target="_blank"
                    >
                      {{ $t('order.returnAndRefund') }}
                    </router-link>
                  </div>
                </el-checkbox>
              </div>
              <!-- 现在购买 -->
              <div class="price__button">
                <el-button type="primary" :disabled="tableData.payList.length === 0" class="button" @click="onClickSubmitOrder">{{ $t('order.Payimmediately') }}</el-button>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="sum__container">
          <dl class="list">
            <dt class="name">总商品金额：</dt>
            <dd class="count">{{ $convertNumberFloat(tableData.goodsTotal || 0) }}</dd>
          </dl>
          <dl class="list">
            <dt class="name">运费：</dt>
            <dd class="count">{{ $convertNumberFloat(tableData.freight || 0) }}</dd>
          </dl>
          <dl class="list">
            <dt class="name">税费：</dt>
            <dd class="count">{{ $convertNumberFloat(tableData.taxation || 0) }}</dd>
          </dl>
        </div>

        <div class="total__container">
          <dl class="count">
            <dt>合计总和：</dt>
            <dd>{{ $convertNumberFloat(tableData.paymentPrice || 0) }}</dd>
          </dl>

          <dl class="address">
            <dt>寄送至：</dt>
            <dd>
              <span class="count">中国</span>
              <span class="detail">{{ selectedAddress.provName || '请选择地址' }} {{ selectedAddress.cityName }} {{ selectedAddress.areaName }} {{ selectedAddress.address }}</span>
            </dd>
            <dt>收货人：</dt>
            <dd>
              <span class="name">{{ selectedAddress.consignee || '请选择地址' }}</span>
              <span class="tel">{{ selectedAddress.mobile || '请选择地址' }}</span>
            </dd>
          </dl>
        </div>

        <div class="button__container">
          <a class="link" href="javascript:void(0)" @click="onClickBackToCartBtn">
            <i class="icon el-icon-back" />
            返回购物车
          </a>

          <el-button class="button" @click="onClickSubmitOrder">提交订单</el-button>
        </div> -->
      </div>
    </div>

    <AuthDialog :visible="isShowAuthDialog" @success="onAuthSuccess" @close="onCloseAuth" />

    <PaymentDialog
      :visible="isShowPaySuccessDialog"
      :order-id="orderId"
      :pay-type="(payType || {}).id"
      :price="(tableData.paymentPrice + '')"
      @success="onClickSuccessBtn"
      @cancel="onClickCancelPaymentBtn"
    />
    <!-- paypal表单 -->
    <order-form :form-data="formData" :action="action" />

    <!-- checkout对话框 -->
    <el-dialog
      :visible="checkoutPayDialog"
      title="请输入信用卡号与有效期"
      width="30%"
      @close="closeCheckoutPayDialog"
    >
      <!-- iframe -->
      <iframe :src="'/html/checkout.html?orderId='+ orderId + '&publicKey=' + publicKey + '&url=' + url + '&token=' + token " style="width: 100%;" frameborder="0" />
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRef, toRefs, watch, computed } from '@vue/composition-api'
import { Message } from 'element-ui'

import AddressItem from '@/components/Address/AddressItem.vue'
// import GoodsTable from '@/components/Common/GoodsTable.vue'
import GoodsItem from '@/components/Common/GoodsItem.vue'
import AddressDialog from '@/components/Address/AddressDialog.vue'
import AuthDialog from '@/components/Common/AuthDialog.vue'
import PaymentDialog from '@/components/Common/PaymentDialog.vue'
import AddressForm from '@/components/Address/AddressForm.vue'
import orderForm from '@/components/OrderForm'

import { useRequest } from '@/utils/request'
import { getDomainPrefix } from '@/utils'
import session from '@/utils/session.js'

export default {
  name: 'OrderSubmit',

  components: {
    AddressItem,
    // GoodsTable,
    AddressDialog,
    AuthDialog,
    PaymentDialog,
    AddressForm,
    GoodsItem,
    orderForm
  },

  setup(_, { root }) {
    const route = root.$route
    const router = root.$router
    const $t = root.$jst
    const data = reactive({
      isShowMoreAddress: false,
      addressList: [],
      defaultAddressIndex: 0,
      tableData: {
        payList: []
      },
      visible: false,
      isEdit: false,
      addressId: 0,
      payType: {},
      orderId: '',
      isShowAuthDialog: false,
      showMoreAddress: false,
      isShowPaySuccessDialog: false,
      countryId: root.$store.state.app.countryItem?.id,
      currencyId: root.$store.state.app.currencyItem?.id,
      isShowDialog: false,
      isAgreed: false,
      formData: {},
      action: process.env.NODE_ENV === 'production' ? 'https://www.paypal.com/cgi-bin/webscr' : 'https://www.sandbox.paypal.com/cgi-bin/webscr',
      checkoutPayDialog: false,
      publicKey: '',
      url: encodeURIComponent(process.env.VUE_APP_BASE_URL),
      token: session.get('token') || ''
      // jumpUrl:computed(()=>{
      //   return `/order/detail?orderid=${data.orderId}`
      // })
    })
    // data.action = process.env.NODE_ENV === 'production' ? 'https://www.paypal.com/cgi-bin/webscr' : 'https://www.sandbox.paypal.com/cgi-bin/webscr'
    // console.log(data.action, 'data.action')
    // 如果是快速购买就要传isQuickBuy quickBuyCount quickBuySkuId 否则不传
    const quickGoods = route.query.quick === '1' ? session.get('goods') : undefined

    const { data: orderData, fetch: getOrderPreviewFetch } = useRequest('order/getOrderPreview', {
      data: computed(() => ({
        cartIdList: route.query.quick !== '1' ? (route.query.cart_ids + '').split(',') : undefined,
        domainPrefix: getDomainPrefix,
        isQuickBuy: route.query.quick === '1' ? '1' : undefined,
        countryId: data.countryId,
        currencyId: data.currencyId,
        ...quickGoods
      })),

      initialData: toRef(data, 'tableData')
    })

    watch(() => orderData.value, res => {
      data.payType = res.payList[0] || {}
    })

    const { data: addressList, fetch } = useRequest('address/addressList', {
      data: {
        pageNo: 1,
        pageSize: 1000
      },

      onSuccess({ data: result }) {
        data.addressList = result.records

        data.defaultAddressIndex = result.records.findIndex(current => current.defaultType === 1)
        data.showMoreAddress = result.records.length > 4

        data.isShowDialog = false
      }
    })

    watch(() => addressList.value, result => {
      // data.addressList = result.records

      // data.defaultAddressIndex = result.records.findIndex(current => current.defaultType === 1)
      // data.showMoreAddress = result.records.length > 4

      // data.isShowDialog = false
    })

    const selectedAddress = computed(() => {
      return (data.addressList[data.defaultAddressIndex] || {})
    })

    const onSetDefault = index => {
      data.defaultAddressIndex = index
      fetch()
      getOrderPreviewFetch()
    }

    const onClickAddressItem = index => {
      data.defaultAddressIndex = index
    }

    const onDeleteAddress = index => {
      fetch()
    }

    const onAddAddressItem = () => {
      data.visible = true
      data.isEdit = false
      data.addressId = 0
    }

    const onEditAddressItem = id => {
      data.visible = true
      data.isEdit = true
      data.addressId = id
    }

    const onSuccess = () => {
      fetch()
    }

    const sumTotal = computed(() => {
      return (data.tableData.paymentPrice || 0) + (data.tableData.freight || 0)
    })

    const onClickBackToCartBtn = _ => {
      router.replace({ name: 'Cart' })
    }

    const onClickPayItem = item => {
      data.payType = item
    }

    const { data: submitOrderResult, fetch: handleSubmitOrderFetch } = useRequest('order/handleSubmitOrderAction', {
      data: {
        cartIdList: route.query.quick !== '1' ? (route.query.cart_ids + '').split(',') : undefined,
        domainPrefix: getDomainPrefix,
        isQuickBuy: route.query.quick === '1' ? '1' : undefined,
        project: 2,
        countryId: data.countryId,
        currencyId: data.currencyId,
        ...quickGoods
      },

      immediate: false,
      isCustom: true
    })

    const { data: handlePayTypeResult } = useRequest('order/handlePayAction', {
      data: computed(() => ({
        orderId: data.orderId,
        payMethodId: data.payType?.id
      })),

      isCustom: true,
      immediate: false,
      isErrorMessage: false
    })

    watch(() => handlePayTypeResult.value, res => {
      if (res.code !== 200) {
        return Message.error($t('order.orderPaymentfailed'))
      }
    })
    // TODO: 点击立即购买触发
    const onClickSubmitOrder = _ => {
      const { cartGoodsList } = data.tableData
      const isCanBuy = cartGoodsList.every((item) => {
        return item.isBuyStatus === 1
      })

      if (!isCanBuy) {
        Message.error($t('common.buyAndaddCart'))
        return
      }
      if (data.soldOutStatus) {
        Message.error($t('common.goodsSoldOut'))
      }
      if (!data.isAgreed) {
        Message.error($t('order.notAgreedPolicy'))
        return
      }

      if (!data.addressList.length) {
        return Message.error($t('order.Pleasereceivingaddress'))
      }
      handleSubmitOrderFetch()
    }

    // const { fetch: handleOnlinePayFetch } =
    // useRequest('order/handleOnlinePayAction', {
    //   data: computed(() => ({
    //     orderId: data.orderId,
    //     paymentId: (data.payType || {}).id
    //   })),

    //   immediate: false
    // })

    const handlePaymentAction = () => {
      data.isShowPaySuccessDialog = true
    }

    const { fetch: pacyPayAction } = useRequest('order/handlePacyPayAction', {
      data: computed(() => ({
        orderId: data.orderId,
        url: window.location.protocol + '//' + window.location.host
      })),

      immediate: false
    })

    const handlePacyPayAction = _ => {
      pacyPayAction()
    }

    // watch(() => pacyPayResult.value, res => {
    // 升级后 PacyPay 代码 start
    // if (res) {
    // window.location = 'http://www.wharfon.com/jump.html?from=pay&url=' + res
    // window.location = res
    // }
    // 升级后 PacyPay 代码 end

    // 升级前 PacyPay 代码 start
    // try {
    //   const { apiUrl, ...params } = JSON.parse(res)

    //   const formAttr = [
    //     ['id', 'submit-form'],
    //     ['action', apiUrl],
    //     ['method', 'POST'],
    //     ['style', 'display: none'],
    //     ['content-type', 'multipart/form-data']
    //   ]
    //   const form = document.createElement('form')

    //   formAttr.forEach(([key, value]) => {
    //     form.setAttribute(key, value)
    //   })

    //   const createInput = attrs => {
    //     const input = document.createElement('input')

    //     Object.entries(attrs).forEach(([key, value]) => {
    //       input.setAttribute(key, value || '')
    //     })

    //     return input
    //   }

    //   const fragment = document.createDocumentFragment()

    //   Object.entries(params).forEach(([key, value]) => {
    //     fragment.appendChild(createInput({
    //       name: key,
    //       value: value
    //     }))
    //   })

    //   form.appendChild(fragment)

    //   const button = document.createElement('button')
    //   button.type = 'submit'

    //   form.appendChild(button)

    //   document.body.appendChild(form)

    //   form.submit()
    // } catch (e) {
    //   return Message.error('解析 JSON 错误')
    // }

    // 升级后 PacyPay 代码 end
    // })

    // paypal 接口函數
    const { fetch: getPayPalPayInfo } = useRequest('order/getPayPalPayInfo', {
      data: computed(() => (
        { orderId: data.orderId,
          url: window.location.origin,
          jumpUrl: `/order/detail` })

      ),
      immediate: false,
      onSuccess: (res) => {
        const formData = JSON.parse(res.data)
        // 这里交给vue响应式维护 FROM 数据，手动提交表单
        data.formData = formData
      },
      onFail: (err) => {
        console.log(err, 'err')
      }
    })

    const handlePayPalAction = _ => {
      getPayPalPayInfo()
    }
    // checkout 接口函數
    const { fetch: getCheckoutPayInfo } = useRequest('order/getCheckoutPayInfo', {
      data: computed(() => (
        { orderId: data.orderId
        })
      ),
      immediate: false,
      onSuccess: (res) => {
        const { publicKey } = res.data
        // const formData = JSON.parse(res.data)
        // 这里交给vue响应式维护 FROM 数据，手动提交表单
        // data.formData = formData
        data.publicKey = publicKey
        data.checkoutPayDialog = true
      },
      onFail: (err) => {
        console.log(err, 'err')
      }
    })

    const handleCheckoutPayAction = _ => {
      getCheckoutPayInfo()
    }
    // 监听 ifrema 消息事件
    window.addEventListener('message', (e) => {
      if (e.data.isSuccess) {
        // TODO: 关闭对话框，从当前页面跳转到订单页面
        data.checkoutPayDialog = false
        router.push({
          path: '/order/detail',
          query: {
            orderid: data.orderId
          }
        })
      }
    })
    watch(() => submitOrderResult.value, res => {
      if (res.code === 100) {
        data.isShowAuthDialog = true
      } else {
        if (res.code === 200) {
          data.orderId = res.data

          switch (data.payType?.mark) {
            case 'weChat_payment':
              // TODO: 调起微信的接口
              handlePaymentAction()
              break

            case 'pacyPay':
              handlePacyPayAction()
              break

            case 'pc_payment':
              // TODO: 调起在线支付接口
              // handlePaymentAction()

              break

            case 'offline':
              // handlePayTypeFetch()
              break

            case 'payPal':
              handlePayPalAction()
              break

            case 'checkout':
              handleCheckoutPayAction()
              break

            default:
              Message.error('请选择支付方式')
              return
          }
        }
      }
    })

    const onCloseAuth = _ => {
      data.isShowAuthDialog = false
    }

    const onAuthSuccess = _ => {
      handleSubmitOrderFetch()
    }

    const onMoreAddress = () => {
      data.showMoreAddress = !data.showMoreAddress
    }

    const onClickCancelPaymentBtn = () => {
      data.isShowPaySuccessDialog = false
      router.replace({ name: 'OrderDetail', query: { orderid: data.orderId }})
    }

    const onClickSuccessBtn = () => {
      data.isShowPaySuccessDialog = false
      router.replace({ name: 'OrderDetail', query: { orderid: data.orderId }})
    }

    const onConfirmAddressForm = _ => {
      fetch()
      getOrderPreviewFetch()
    }

    const onClickChangeAddressBtn = _ => {
      data.isShowDialog = true
    }

    const onCloseSelectAddressDialog = _ => {
      data.isShowDialog = false
    }

    const closeCheckoutPayDialog = (_) => {
      data.checkoutPayDialog = false
    }

    return {
      ...toRefs(data),
      sumTotal,
      selectedAddress,
      onSetDefault,
      onClickAddressItem,
      onDeleteAddress,
      onAddAddressItem,
      onEditAddressItem,
      onSuccess,
      onClickBackToCartBtn,
      onClickPayItem,
      onClickSubmitOrder,
      onCloseAuth,
      onAuthSuccess,
      closeCheckoutPayDialog,
      onMoreAddress,
      onClickCancelPaymentBtn,
      onClickSuccessBtn,
      onConfirmAddressForm,
      onClickChangeAddressBtn,
      onCloseSelectAddressDialog
    }
  }
}
</script>

<style lang="scss">
.submit__container {
  padding: 0 0 100px;
}

.submit__wrap {
  width: 96.9%;
  min-width: 1200px;
  max-width: 1600px;
  margin: 0 auto;
}

.submit__title-wrap {
  position: relative;
  z-index: 2;
  background: #fff;

  .submit__title {
    width: 1200px;

    margin: 0 auto;
    letter-spacing: 2px;
    font-size: 18px;

    .title {
      display: inline-block;
      padding: 33px 0 26px;
      border-bottom: 2px solid #0049AC;
      color: #0D5CAB;
    }
  }
}

.submit__body {
  position: relative;
  box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);
  z-index: 1;

  .submit__body-wrap {
    display: flex;
    width: 1200px;
    padding: 0 11px;
    margin: 0 auto;
  }

  .left {
    flex-shrink: 0;
    width: 764px;
  }

  .right {
    margin-top: 23px;

    .right-wrap {
      width: 390px;
      padding: 32px 40px;
      background: #fff;

      .price__header {
        padding-bottom: 22px;
        border-bottom: 1px solid #0049AC;
        font-size: 16px;
        font-weight: bolder;
        color: #0049AC;
      }

      .price__detail {
        padding: 32px 0;
        border-bottom: 1px solid #EEEEEE;

        &.no-border-bottom {
          border-bottom: none;
        }

        .item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }

          dt {
            font-size: 16px;
            font-weight: bolder;
            color: #222222;
          }

          dd {
            font-size: 16px;
            font-weight: bolder;

            color: #222222;

            &.red {
              color: #FF2900;
            }
          }
        }
      }

      .price__button {
        padding: 0 58px;
        margin-top: 81px;

        .button {
          width: 100%;
          height: 50px;
          border: 1px solid #0049AC;
          border-radius: 25px;
          background: #0049AC;
          color: #fff;

        }
        .is-disabled {
          background: #6e7a8c;
          border: 1px solid #6e7a8c;
        }
      }
    }
  }
}

.base__section {
  padding: 32px 40px 51px;
  border-bottom: 1px solid #F0F0F0;
  border-radius: 8px;
  margin-bottom: 30px;
  background: #fff;
  // padding: 21px 0;

  &.address {
    padding-bottom: 30px;
  }
}

.base__container {
  padding: 0 20px 24px;
  margin: 24px 0;

  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 22px;
    border-bottom: 1px solid #EEEEEE;

    .title {
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
      color: #6AABED;
    }

    .link {
      font-size: 12px;
      line-height: 22px;
      vertical-align: middle;
      color: #666666;

      .icon {
        display: inline-block;
        margin-left: 6px;
        font-size: 15px;
        line-height: 22px;
        vertical-align: middle;
        color: #4981E8;
      }
    }
  }

  .address__list {
    @include clearfix;

    height: 150px;
    overflow: hidden;
    .item {
      float: left;
      margin: 0 10px 10px 0;

      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }
    }
  }

  .more-btn {
    padding: 26px 13px 0;
    font-size: 12px;

    .btn {
      color: #666;
    }

    .icon {
      display: inline-block;
      margin-left: 5px;

      transform: rotate(90deg);
    }
  }
}

.pay__list {
  padding: 13px 5px;
  @include clearfix;

  .item {
    position: relative;
    float: left;
    min-width: 120px;
    border: 1px solid #6AABED;
    border-radius: 4px;
    margin-right: 15px;
    line-height: 40px;
    text-align: center;

    &.active {
      border-color: #6AABED;
      background: #6AABED;

      .link {
        color: #fff;
      }

      .icon {
        display: block;
      }
    }

    .link {
      display: block;
      color: #6AABED;
    }

  }
}

.sum__container {
  padding: 31px 22px 26px;

  .list {
    margin-bottom: 12px;
    text-align: right;

    &:last-child {
      margin-bottom: 0;
    }

    dd, dt {
      display: inline-block;
      font-size: 14px;
      line-height: 22px;
      color: #333333;
    }

    .count {
      width: 135px;
      text-align: right;

      @include currency;
    }
  }
}

.total__container {
  padding: 23px 22px 18px;
  background: #F3F3F3;

  .count {
    text-align: right;

    dd, dt {
      display: inline-block;
      font-size: 14px;
      // line-height: 22px;
      color: #333333;
    }

    dd {
      width: 135px;
      font-size: 18px;
      // line-height: 26px;
      font-weight: bolder;
      color: #FF2900;

      @include currency;
    }
  }

  .address {
    margin-top: 14px;
    text-align: right;

    dd, dt {
      display: inline-block;
      font-size: 13px;
      color: #333333;
    }

    dd + dt {
      margin-left: 5px;
    }

    .tel {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

.button__container {
  padding: 9px 20px;
  text-align: right;

  .link {
    margin-right: 48px;
    font-size: 12px;
    color: #0049AC;

    .icon {
      display: inline-block;
      margin-right: 9px;
    }
  }

  .button {
    width: 184px;
    border: 1px solid #4981E8;
    background: #4981E8;
    color: #fff;
  }
}

.goods__section {
  .shop-header {
    margin-bottom: 20px;

    .icon {
      font-size: 13px;
      color: #AAAAAA;
    }

    .name {
      display: inline-block;
      margin-left: 5px;
      font-size: 16px;
      color: #222222;
    }
  }

}

.price__policy {
  width: 100%;

  .checkbox-wrap {
    width: 100%;
  }
  .checkbox {
    min-width: 300px;
    word-break: break-all;
    white-space: pre-line;
    line-height: 20px;
    color: #666666;
  }

  .link {
    color: #6AABED;
  }

}
</style>

<style lang="scss">
.price__policy {
  .el-checkbox__input {
    vertical-align: top;
  }

  .el-checkbox__label {
    width: 100%;

  }
}
</style>
