<!--
 * @Author: Neko
 * @Date: 2021-01-08 10:44:22
 * @LastEditTime: 2021-04-21 10:14:27
 * @LastEditors: Neko
-->
<template>
  <div
    class="address-item__container"
    :class="{
      default: data.defaultType === 1,
      active: isDefault
    }"
    @click="onChangeActive"
  >
    <div class="header">
      <span class="info name">{{ data.consignee }}</span>
      <span class="info tel">{{ data.mobile }}</span>
    </div>

    <div class="content">
      <p class="address">
        {{ data.countryName }} {{ data.provName }} {{ data.region }} {{ data.cityName }} {{ data.areaName }} {{ data.address }}
      </p>

      <!-- <dl class="country">
        <dt>收货国家：</dt>
        <dd>日本</dd>
      </dl> -->

      <!-- <div class="buttons">
        <a class="link" href="javascript:void(0)" @click="onClickEditBtn">修改</a>
        <a class="link" href="javascript:void(0)" @click="onClickDeleteBtn">删除</a>
      </div> -->
    </div>

    <a href="javascript:void(0)" class="default-label">{{ this.$t('common.Defaultaddress') }}</a>
    <a href="javascript:void(0)" class="set-default-label" @click.stop="onClickSetDefaultBtn">{{ this.$t('common.Setdefault') }}</a>

    <span class="default-check">
      <i class="icon el-icon-check" />
    </span>
  </div>
</template>

<script>
import { watch } from '@vue/composition-api'
import { Message, MessageBox } from 'element-ui'

import { useRequest } from '@/utils/request'

export default {
  name: 'AddressItem',

  emits: ['set-default', 'click', 'delete', 'edit'],

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    isDefault: {
      type: Number,
      default: 0
    }
  },

  setup(props, { emit, root }) {
    const $t = root.$jst
    const { fetch, data: handleSetDefaultResult } = useRequest('address/setDefault', {
      data: {
        id: props.data.id
      },
      immediate: false,

      onSuccess({ data: result }) {
        if (result) {
          Message.success($t('common.Operationsuccessful'))
          emit('set-default')
        }
      }
    })

    const onClickSetDefaultBtn = _ => {
      fetch()
    }

    watch(() => handleSetDefaultResult.value, result => {
      // if (result) {
      //   Message.success('操作成功')
      //   emit('set-default')
      // }
    })

    const onChangeActive = _ => {
      emit('click')
    }

    const { fetch: handleDeleteAction, data: handleDeleteResult } = useRequest('address/addressDelete', {
      data: {
        id: props.data.id
      },
      immediate: false
    })

    const onClickDeleteBtn = _ => {
      MessageBox.confirm($t('common.Deleteaddress'), $t('common.Tips'))
        .then(() => {
          handleDeleteAction()
        })
        .catch(() => {})
    }

    const onClickEditBtn = _ => {
      emit('edit', props.data.id)
    }

    watch(() => handleDeleteResult.value, result => {
      if (result) {
        Message.success($t('common.Operationsuccessful'))
        emit('delete')
      }
    })

    return {
      onClickDeleteBtn,
      onClickEditBtn,
      onClickSetDefaultBtn,
      onChangeActive
    }
  }
}
</script>

<style lang="scss" scoped>
.address-item__container {
  position: relative;
  width: 282px;
  height: 142px;
  padding: 10px 16px 0;
  border: 1px solid #DADADA;
  margin-bottom: 10px;

  &:hover {
    .content .buttons {
      display: block;
    }
  }

  &:not(.default) {
    &:hover {
      background: #F9FCFF;

      .set-default-label {
        display: block;
      }

    }
  }

  &.active {
    border-color: #0049AC;
  }

  .header {
    margin-bottom: 8px;

    .info {
      display: inline-block;
      font-size: 12px;
      color: #333333;
    }

    .name {
      margin-right: 42px;
    }
  }

  .content {
    padding: 4px 0 9px;
    border-top: 1px solid #F0F0F0;

    font-size: 12px;
    color: #333333;

    .address {
      height: 40px;
      margin-bottom: 10px;
      line-height: 20px;
      @include line-clamp(2);
    }

    .country {
      dt, dd {
        display: inline-block;
        margin: 0;
      }
    }

    .buttons {
      display: none;
      margin-top: 20px;

      .link {
        display: inline-block;
        font-size: 12px;
        // line-height: 20px;
        color: #0049AC;

        & + .link {
          margin-left: 20px;
        }
      }
    }
  }

  @mixin label {
    position: absolute;
    padding: 4px 7px 5px;
    top: 0;
    right: 0;
    font-size: 12px;
    text-align: center;
  }

  .default-label {
    display: none;
    background: #DADADA;
    color: #fff;

    @include label;
  }

  .set-default-label {
    display: none;
    background: #E0EAF7;
    color: #0049AC;

    @include label;
  }

  .default-check {
    display: none;
  }

  &.default {
    .default-label {
      display: block;
    }

    .default-check {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 18px;
      height: 18px;
      padding-top: 2px;
      text-align: right;
      background: linear-gradient(135deg, #fff 0, #fff 50%, #4981E8 50%);

      .icon {
        font-size: 12px;
        color: #fff;
      }
    }

  }
}
</style>
